<template>
<!-- *****
	该
	页
	面
	暂
	时
	弃
	用
	******-->
    <div class="cardSuccessView">
        <div class="bcView">
            <div class="tView">
                <img src="https://static.bokao2o.com/wisdomDesk/images/Def_Icon_PaySuccess.png">
                充值成功！
            </div>
<!--            <div class="orderInfoView">-->
<!--                <label>单据编号：2008270007</label>-->
<!--                <label>订单日期：2020-08-27 15:46</label>-->
<!--            </div>-->
<!--            <div class="cardInfoView">-->
<!--                <label>卡片名称：8折卡</label>-->
<!--                <label>1 17521200009</label>-->
<!--            </div>-->
<!--            <div class="accInfoView">-->
<!--                <div class="headerView">-->
<!--                    <label>名称</label>-->
<!--                    <label>类型</label>-->
<!--                    <label>异动资金</label>-->
<!--                    <label>余额</label>-->
<!--                </div>-->
<!--                <div class="accInfoItem">-->
<!--                    <label>储值账户</label>-->
<!--                    <label>储值</label>-->
<!--                    <label>600</label>-->
<!--                    <label>600</label>-->
<!--                </div>-->
<!--            </div>-->
        </div>
        <div class="btnView">
            <button class="btn-print" @click="printPaper">打印</button>
            <button class="btn-comp" @click="$router.push({name: 'home'})">确认</button>
        </div>
    </div>

</template>

<script>
    import {getLodop} from '@/print/LodopFuncs'
    export default {
        name: "applyCardSuccess",
        data() {
            return {
                result: {}
            }
        },
        created() {
            // 获取办卡完成的参数
            if (this.$route.params.data) {
                this.result = JSON.parse(this.$route.params.data);
            }
        },
        methods: {
            // 打印
            printPaper() {
                console.log("打印:"+this.result);
                let LODOP = getLodop();
                LODOP.SET_LICENSES("", "660FDFD7D9F7F7101A272F2EB57C306A", "C94CEE276DB2187AE6B65D56B3FC2848", "");

                LODOP.PRINT_INIT("收银小票");               //首先一个初始化语句
                LODOP.SET_PRINT_PAGESIZE(3, "75mm", "20mm", "收银小票");

                let height = 0;

                //门店信息
                LODOP.ADD_PRINT_TEXT(height + "mm", "0mm", "75mm", "4mm", this.result.store.storeName + "充值");
                LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
                LODOP.SET_PRINT_STYLEA(0, "FontSize", 14);
                LODOP.SET_PRINT_STYLEA(0, "Bold", 1);
                //门店电话
                height = height + 6;
                LODOP.ADD_PRINT_TEXT(height + "mm", "0mm", "75mm", "3mm", "门店电话：" + this.result.store.phone);
                LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
                LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
                //门店地址
                height = height + 4;
                LODOP.ADD_PRINT_TEXT(height + "mm", "0mm", "75mm", "3mm", "地址：" + this.result.store.address);
                LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
                LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);

                //线
                height = height + 6;
                LODOP.ADD_PRINT_LINE(height + "mm", 0, "16mm", "75mm", 3, 0);
                //充值信息
                // height = height + 6;
                // LODOP.ADD_PRINT_TEXT(height + "mm", "0mm", "75mm", "3mm", "会员卡号：" + this.result.cardNo === '' ? this.result.phone : this.rechargeForm.cardNo);
                // LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
                height = height + 6;
                LODOP.ADD_PRINT_TEXT(height + "mm", "0mm", "75mm", "3mm", "会员姓名：" + this.result.realName);
                LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
                height = height + 6;
                LODOP.ADD_PRINT_TEXT(height + "mm", "0mm", "75mm", "3mm", "会员电话：" + this.result.phone);
                LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
                height = height + 6;
                LODOP.ADD_PRINT_TEXT(height + "mm", "0mm", "75mm", "3mm", "会员卡名：" + this.result.cardName);
                LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
                height = height + 6;
                LODOP.ADD_PRINT_TEXT(height + "mm", "0mm", "75mm", "3mm", "充值时间：" + this.getTime());
                LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
                height = height + 6;
                LODOP.ADD_PRINT_TEXT(height + "mm", "0mm", "75mm", "3mm", "充值金额：" + this.result.payPrice + "元");
                LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
                height = height + 6;
                LODOP.ADD_PRINT_TEXT(height + "mm", "0mm", "75mm", "3mm", "付款方式：" + this.result.payWayName);
                LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
                height = height + 6;
                LODOP.ADD_PRINT_TEXT(height + "mm", "0mm", "75mm", "3mm", "顾客签名：_________");
                LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);

                LODOP.SET_PRINTER_INDEX(-1)
                LODOP.PRINT();
            },

            getTime() {
                var date1 = new Date();
                var year = date1.getFullYear();
                var month = date1.getMonth() + 1;
                var day = date1.getDate();
                var hours = date1.getHours();
                var minutes = date1.getMinutes();
                var seconds = date1.getSeconds();
                return year + "年" + month + "月" + day + "日" + hours + ":" + minutes + ":" + seconds
            }
        }
    }
</script>

<style scoped lang="scss">
    .cardSuccessView {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: #fff;
        z-index: 10;
        padding-top: 8%;

        > .bcView {
            position: relative;
            left: 0;
            right: 0;
            margin: auto;
            width: 560px;
            background: #f8f8f8;
            border-radius: 10px;
            font-size: 14px;
            height: 80%;
            padding: 0 30px 25px 30px;
            overflow-x: hidden;
            overflow-y: auto;

            > .tView {
                line-height: 46px;
                font-size: 24px;
                color: #333;
                padding: 35px 0 40px 0;
                text-align: center;
                font-family: PingFangSC-Semibold;

                > img {
                    width: 46px;
                    height: 46px;
                    vertical-align: top;
                    margin-right: 20px;
                }
            }

            > .orderInfoView {
                display: flex;

                > label {
                    flex: 1;
                    line-height: 30px;
                    color: #666;
                }
            }

            > .cardInfoView {
                display: flex;
                padding-bottom: 10px;
                border-bottom: 1px dashed rgba(154, 154, 154, 0.5);

                > label {
                    flex: 1;
                    line-height: 30px;
                    color: #333;
                }
            }

            > .accInfoView {

                > .headerView {
                    line-height: 44px;
                    color: #666;
                }

                > div {
                    display: flex;

                    > label {
                        flex: 1;
                        text-align: center;
                    }

                    > label:nth-child(1) {
                        flex: 2;
                        text-align: left;
                    }

                    > label:last-child {
                        text-align: right;
                    }
                }

                > .accInfoItem {
                    line-height: 20px;
                    padding: 9px 0;
                    font-size: 15px;

                    > div {
                        display: flex;

                        > label {
                            flex: 1;
                            text-align: center;
                        }

                        > label:nth-child(1) {
                            flex: 2;
                            text-align: left;
                        }

                        > label:last-child {
                            text-align: right;
                        }
                    }
                }
            }
        }

        > .btnView {
            margin-top: 40px;
            text-align: center;

            > .btn-print {
                width: 230px;
                height: 44px;
                padding: 0;
                line-height: 44px;
                background: #47BF7C;
                font-size: 14px;
                font-family: PingFangSC-Semibold;
                color: #fff;
                border-radius: 6px;
                margin-right: 20px;
            }

            > .btn-comp {
                width: 230px;
                height: 44px;
                padding: 0;
                line-height: 44px;
                background: #3370ff;
                font-size: 14px;
                font-family: PingFangSC-Semibold;
                color: #fff;
                border-radius: 6px;
            }
        }
    }

    div {
        box-sizing: border-box;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    button {
        background: transparent;
        border: none;
        outline: none;
        box-sizing: border-box;
        cursor: pointer;
    }
</style>
